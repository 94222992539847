import { useUserStore } from './user.store'

const APP_PATRON = 'APP_PATRON' // якщо були покупки
const APP_SUBSCRIBER = 'APP_SUBSCRIBER' // якщо є підписка
const APP_ACQUIRED = 'APP_ACQUIRED' // якщо платний трафік
const APP_SUSPECTED = 'APP_SUSPECTED' // якщо з України
const APP_MODERATOR = 'APP_MODERATOR' // якщо з модератор

export function isOrganic(): boolean {
  return !isPaid()
}

export function isPaid(): boolean {
  const store = useUserStore()
  if (store.me?.roles.includes(APP_ACQUIRED)) return true
  return false
}

export function isSubscriber(): boolean {
  const store = useUserStore()
  if (store.me?.roles.includes(APP_SUBSCRIBER)) return true
  return false
}

export function isPatron(): boolean {
  const store = useUserStore()
  if (store.me?.roles.includes(APP_PATRON)) return true
  return false
}

export function isSuspected(): boolean {
  const store = useUserStore()
  if (store.me?.roles.includes(APP_SUSPECTED)) return true
  return false
}

export function isModerator(): boolean {
  const store = useUserStore()
  if (store.me?.roles.includes(APP_MODERATOR)) return true
  return false
}
